<template>
	<div>
		<div v-html="$t('app.home.embark_skills')" class="text-h6 text-md-h5"></div>

		<v-container class="text-center mt-6">
			<v-card
				:width="cardDimensions"
				:height="340"
				class="elevation-4 mx-4 mt-4 home-features-card-wr"
				flat
				v-for="(item, index) in components"
				:key="index"
			>
				<v-card-text align="center" class="">
					<v-img
						:src="require(`@/assets/images/website/${item.img}.png`)"
						:width="imageWidth"
					></v-img>
				</v-card-text>

				<v-card-text class="text-h6 font-weight-bold wr_indigo_2--text">
					{{ $t(item.title) }}
				</v-card-text>

				<v-card class="wr_indigo_2" height="100%">
					<v-card-text class="subtitle-1 white--text">
						{{ $t(item.desc) }}
					</v-card-text>
				</v-card>
			</v-card>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "WebsitePlatform",

	computed: {
		imageWidth() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm
				? 60
				: 70;
		},

		cardDimensions() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm
				? ""
				: 310;
		},

		components() {
			return [
				{
					title: "app.home.learning_journeys",
					desc: "app.home.learning_journeys.desc",
					img: "growth",
				},
				{
					title: "app.home.tools_for_mentor",
					desc: "app.home.tools_for_mentor.desc",
					img: "gear",
				},
				{
					title: "app.home.evaluation_and_support",
					desc: "app.home.evaluation_and_support.desc",
					img: "call-center",
				},
			];
		},
	},
};
</script>

<style></style>

<template>
	<div flat class="mt-16" height="100%">
		<div align="center" style="width:100%" class="mt-16">
			<!-- <AppButton
				label="app.join_waitlist"
				color="greenAccent2"
				:class="['font-weight-bold']"
				:large="true"
				:icon="$vuetify.rtl ? 'left_chevron_double' : 'right_chevron_double'"
				@click="openGoogleForm()"
			/> -->
		</div>

		<v-card
			flat
			v-if="show_google_form"
			class="mt-10 greyLighten4 relative-pos-wr"
			align="center"
			id="g-form"
			height="500px"
		>
			<iframe
				:src="iframeSrc"
				class="responsive-iframe full-height-wr"
				frameborder="0"
				marginheight="0"
				marginwidth="0"
				@load="onLoad"
			></iframe>
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "WebsiteBookJourney",

	data() {
		return {
			show_google_form: false,
		};
	},

	computed: {
		...mapGetters({
			userLang: "user/getLanguage",
		}),

		iframeSrc() {
			if (this.userLang == "he") {
				return "https://docs.google.com/forms/d/e/1FAIpQLSfZ5Z6-z82VhwxPK-K6YadVljtjl5qcUDfs9D1bCKE6lBHR_A/viewform?embedded=true";
			}
			return "https://docs.google.com/forms/d/e/1FAIpQLSe8cUb9tlwR4yQleHFKqCr0GzzMOTQbpGHsb4am84HTyvFVZA/viewform?embedded=true";
		},
	},

	methods: {
		openGoogleForm() {
			try {
				this.show_google_form = true;
				this.$nextTick(() => {
					document.getElementById("g-form").scrollIntoView({
						behavior: "smooth",
					});
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.responsive-iframe {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
}
</style>

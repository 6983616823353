<template>
	<Website></Website>
</template>

<script>
import Website from "@/components/website/Index.vue";

export default {
	name: "WebsitePage",

	components: {
		Website,
	},
};
</script>

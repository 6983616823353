<template>
	<div>
		<!-----------------------------------------------
			SHOW ON DEVICES WHICH ARE SMALLER THAN MEDIUM SIZE
		 ------------------------------------------------>

		<v-card color="wr_indigo_2" dark>
			<v-card-text
				class="font-weight-bold white--text subtitle-1 py-3 wr_indigo_2"
				align="center"
			>
				{{ $t("app.home.slider") }}
			</v-card-text>
			<v-card-text class="white">
				<div class="d-none d-md-block ">
					<v-row no-gutters>
						<v-col
							sm="5"
							v-for="(item, index) in modules"
							:key="index"
							offset-lg="1"
							class="pa-2"
						>
							<v-btn
								fab
								x-small
								depressed
								outlined
								disabled
								color="amberDarken1"
								:style="{
									border: '2px solid var(--v-amberDarken1-base)',
								}"
								class="subtitle-1 font-weight-bold"
							>
								<span class="black--text">{{ index + 1 }}</span>
							</v-btn>
							<span
								class="font-weight-bold ms-4 black--text text-sm-subtitle-1 text-body-2"
								>{{ $t(item.title) }}</span
							>
						</v-col>
					</v-row>
				</div>
				<div
					v-for="(item, index) in modules"
					:key="index"
					class="ma-2 d-md-none"
				>
					<v-btn
						fab
						x-small
						depressed
						outlined
						disabled
						color="amberDarken1"
						:style="{
							border: '2px solid var(--v-amberDarken1-base)',
						}"
						class="subtitle-1 font-weight-bold"
					>
						<span class="black--text">{{ index + 1 }}</span>
					</v-btn>
					<span
						class="font-weight-bold ms-4 black--text text-sm-subtitle-1 text-body-2"
						>{{ $t(item.title) }}</span
					>
				</div>
			</v-card-text>
		</v-card>
		<v-timeline :reverse="shouldReverse" :dense="!shouldReverse" class="mt-6">
			<v-timeline-item
				v-for="(item, index) in modules"
				:key="index"
				:color="item.background"
				small
			>
				<v-expansion-panels>
					<v-expansion-panel>
						<v-expansion-panel-header
							:class="[
								'font-weight-bold white--text text-subtitle-1 text-md-title',
								item.background,
							]"
							>{{ index + 1 }}. {{ $t(item.title) }}</v-expansion-panel-header
						>
						<v-expansion-panel-content>
							<v-card-text
								class="black--text subtitle-1"
								v-html="$t(item.description)"
							>
							</v-card-text>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-timeline-item>
		</v-timeline>
	</div>
</template>

<script>
export default {
	title: "WebsiteModulesSlider",

	data() {
		return {
			panel: [0],
		};
	},

	computed: {
		modules() {
			return [
				{
					title: "app.home.slider.plan_and_manage.title",
					background: "teal",
					color: "black",
					description: "app.home.slider.plan_and_manage.subtitle",
				},
				{
					title: "app.home.slider.m2.title",
					background: "orange",
					color: "black",
					description: "app.home.slider.m2.subtitle",
				},
				{
					title: "app.home.slider.m3.title",
					background: "purple",
					color: "white",
					description: "app.home.slider.m3.subtitle",
				},
				{
					title: "app.home.slider.m4.title",
					background: "pink",
					color: "white",
					description: "app.home.slider.m4.subtitle",
				},
				{
					title: "app.home.slider.m5.title",
					background: "indigo",
					color: "white",
					description: "app.home.slider.m5.subtitle",
				},
				{
					title: "app.home.slider.m6.title",
					background: "green",
					color: "black",
					description: "app.home.slider.m6.subtitle",
				},
			];
		},

		shouldReverse() {
			return window.innerWidth > this.$vuetify.breakpoint.thresholds.md;
		},
	},
};
</script>

<style scoped>
.slider-content-wr {
	max-height: 400px;
	overflow-y: auto;
}
</style>

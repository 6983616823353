<template>
	<div>
		<div
			v-html="
				$t('app.home.description_1', {
					weresearch: $t('app.weresearch_with_style'),
				})
			"
			class="text-h6 text-md-h5"
		></div>

		<div
			v-html="$t('app.home.description_2')"
			class="mt-7 text-h6 text-md-h5"
		></div>

		<div
			v-html="
				$t('app.home.description_3', {
					weresearch: $t('app.weresearch_with_style'),
				})
			"
			class="my-7 text-h6 text-md-h5"
		></div>

		<ModulesSlider></ModulesSlider>

		<Tools></Tools>
	</div>
</template>

<script>
import ModulesSlider from "@/components/website/journeys/ModulesSlider.vue";
import Tools from "@/components/website/journeys/Tools.vue";

export default {
	name: "WebsiteJourneys",

	components: {
		ModulesSlider,
		Tools,
	},
};
</script>

<style></style>

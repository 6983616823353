<template>
	<div>
		<v-row no-gutters>
			<v-col>
				<ProgramForStudents></ProgramForStudents>
			</v-col>
			<v-col>
				<ProgramForTeachers></ProgramForTeachers>
			</v-col>
		</v-row>
		<v-card class="mt-15" flat>
			<v-card-text align="center">
				<!-- <AppButton
					label="app.order_program_msg"
					color="greenAccent2"
					:class="['font-weight-bold']"
					:large="true"
					:icon="$vuetify.rtl ? 'left_chevron_double' : 'right_chevron_double'"
					@click="redirectTo()"
				/> -->
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import ProgramForStudents from "@/components/website/programs/ForStudent.vue";
import ProgramForTeachers from "@/components/website/programs/ForTeacher.vue";

export default {
	name: "WebsiteAboutPrograms",

	components: {
		ProgramForStudents,
		ProgramForTeachers,
	},

	methods: {
		redirectTo() {
			this.$router.push({
				name: "Programs",
				query: {
					lang: this.$route.query.lang,
				},
			});
		},
	},
};
</script>

<style scoped>
.book-btn-wr span {
	transition: 0.5s !important;
}
</style>

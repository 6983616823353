<template>
	<div class="mt-10">
		<div
			v-html="
				$t('app.home.tools.description_1', {
					weresearch: $t('app.weresearch_with_style'),
				})
			"
			class="mt-3 text-h6 text-md-h5"
		></div>

		<v-container class="text-center mt-6">
			<template v-for="(feature, index) in features">
				<v-card
					:key="index"
					:width="cardWidth"
					:height="cardHeight"
					class="elevation-4 mx-4 mt-4 home-features-card-wr"
					flat
				>
					<v-row no-gutters align="center" justify="center" class="fill-height">
						<v-col align="center">
							<v-img
								:src="require(`@/assets/images/website/${feature.img}.png`)"
								:width="imageWidth"
							></v-img>
						</v-col>
					</v-row>

					<v-footer
						absolute
						bottom
						tile
						dark
						class="justify-center pa-3 font-weight-bold subtitle-1"
						color="wr_indigo_2"
					>
						{{ $t(feature.text) }}
					</v-footer>
				</v-card>
			</template>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "WebsiteTools",

	computed: {
		cardWidth() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm
				? 250
				: 300;
		},

		cardHeight() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm
				? 200
				: 250;
		},

		imageWidth() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm
				? 60
				: 70;
		},

		features() {
			return [
				{
					text: "app.home.tools.project_management",
					img: "iteration",
				},
				{
					text: "app.home.tools.analysis",
					img: "data-analysis",
				},
				{
					text: "app.home.tools.citation",
					img: "double-quotes",
				},

				{
					text: "app.home.tools.writing",
					img: "text",
				},
				{
					text: "app.home.tools.reading",
					img: "study",
				},
				{
					text: "app.home.tools.documenting",
					img: "mind-map",
				},
				{
					text: "app.home.tools.collaboration",
					img: "people",
				},
				{
					text: "app.home.tools.presenting",
					img: "teacher",
				},
				{
					text: "app.home.tools.reflection",
					img: "flip",
				},
			];
		},
	},
};
</script>

<template>
	<div class="full-height-wr">
		<v-container class="mt-6 full-height-wr">
			<v-card
				class="elevation-4 mx-4 mt-4 home-features-card-wr"
				flat
				height="100%"
			>
				<v-card-text align="center">
					<v-img
						:src="require(`@/assets/images/website/statistics.png`)"
						:width="imageWidth"
					></v-img>
				</v-card-text>

				<v-card-text
					class="text-subtitle-1 text-md-h6 font-weight-bold wr_indigo_2--text"
					align="center"
				>
					{{ $t("app.home.program.for_teachers") }}
					<div class="font-weight-thin greyDarken4--text">
						{{ $t("app.home.program_in_languages") }}
					</div>
				</v-card-text>

				<v-card class="wr_indigo_2" height="100%">
					<v-card-text class="title white--text px-10 pt-10 pb-0">
						<div v-html="$t('app.home.program.for_teachers.line1')"></div>
						<div
							class="mt-2"
							v-html="$t('app.home.program.for_teachers.line2')"
						></div>
						<div
							class="mt-2"
							v-html="$t('app.home.program.for_teachers.line3')"
						></div>
						<!-- <div class="mt-2">
							{{ $t("app.home.program.for_teachers.line4") }}
							<div
								v-html="$t('app.home.program.for_teachers.line4.list')"
							></div>
						</div> -->
					</v-card-text>
				</v-card>
			</v-card>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "WebsiteProgramForTeacher",

	data() {
		return {
			know_more: false,
		};
	},

	computed: {
		imageWidth() {
			return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm
				? 60
				: 80;
		},
	},
};
</script>

<style></style>

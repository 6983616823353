<template>
	<div
		v-html="
			$t('app.home.about.desc', {
				skillsx: $t('app.skillsx_with_style'),
			})
		"
		class="text-h6 text-md-h5"
	></div>
</template>

<script>
export default {
	name: "WebsiteAbout",
};
</script>

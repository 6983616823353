var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 text-md-h5",domProps:{"innerHTML":_vm._s(
			_vm.$t('app.home.description_1', {
				weresearch: _vm.$t('app.weresearch_with_style'),
			})
		)}}),_c('div',{staticClass:"mt-7 text-h6 text-md-h5",domProps:{"innerHTML":_vm._s(_vm.$t('app.home.description_2'))}}),_c('div',{staticClass:"my-7 text-h6 text-md-h5",domProps:{"innerHTML":_vm._s(
			_vm.$t('app.home.description_3', {
				weresearch: _vm.$t('app.weresearch_with_style'),
			})
		)}}),_c('ModulesSlider'),_c('Tools')],1)}
var staticRenderFns = []

export { render, staticRenderFns }